<template>
    <div class="text-left askBuyDetail" style="background:#fff">
        <!-- 分享海报 -->
        <sharePoster ref="sharePoster"></sharePoster>

        <div class="breadcrumbBarBox p-t-20 p-b-10">
            <div class="pageCenter breadcrumbBar" :style="{width: this.$oucy.pageWidth+'px'}">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item v-for="(item,index) in levelList" :key="index" :to="item.path" class="breadcrumb-item">{{item.meta.title}}</el-breadcrumb-item>
                </el-breadcrumb>
                <!-- <el-button icon="el-icon-notebook-2" type="text">帮助手册</el-button> -->
            </div>
        </div>
        <!--最新求购-->
        <div class="main p-b-50">
            <div class="want_main" :style="{width: this.$oucy.pageWidth+'px'}">
                <el-row>
                    <!-- 左边大图 -->
                    <el-col :span="8">
                        <div class="box" v-if="getFormType(detail.buyAskForms, 7)">
                            <div class="f14" v-for="v of detail.buyAskForms" :key="v.id" v-if="v.buyAskCategoryForm.formType==7">
                                <div class="">
                                    <carousel :carousel="v.value"></carousel>
                                </div>
                            </div>
                        </div>
                        <div
                          v-else
                          class="text-center df-ai-c df-jc-c"
                          style="background: #f1f1f1; margin-bottom: 10px"
                        >
                          <el-image
                            style="color: #aaa; width: 380px; height: 380px"
                          >
                            <div slot="error" class="image-slot">
                              <i
                                class="el-icon-picture-outline m-t-100"
                                style="font-size: 80px"
                              ></i>
                              <div>无图</div>
                            </div>
                          </el-image>
                        </div>
                    </el-col>

                    <!-- 右边 文字 -->
                    <el-col :offset="1" :span="15">
                        <div class="box">
                            <div class="title f20 bold df-jc-s-b">
                                <div class="">
                                    <span class="category" v-if="detail.ltBuyAskCategoryDto">{{detail.ltBuyAskCategoryDto.categoryName}}</span>
                                    <!-- 标题 -->
                                    <span class="bold m-l-15">{{detail.askTitle}}</span>
                                </div>
                                <div class="df-jc-s-b" v-if="loginUser.id == detail.askUser">
                                    <el-tooltip popper-class="tooltipitem" effect="light" content="编辑" placement="top">
                                        <div class="operation m-l-15 df-jc-c df-ai-c"  @mouseenter="enter(1)" @mouseleave="leave()" @click="$oucy.go('ReleaseCenterAskBuySave?id='+detail.id)">
                                            <img src="@/assets/icon/edit_mouse.png" v-if="operation == 1"/>
                                            <img src="@/assets/icon/edit_not.png" v-else/>
                                        </div>
                                    </el-tooltip>
                                    <el-tooltip popper-class="tooltipitem" effect="light" content="取消置顶" placement="top" v-if="detail.askTopping">
                                        <div class="operation m-l-15 df-jc-c df-ai-c"  @mouseenter="enter(2)" @mouseleave="leave()" @click="buyAskCancel(detail.id)">
                                            <img src="@/assets/icon/offTopping.png" v-if="operation == 2"/>
                                            <img src="@/assets/icon/offTopping_not.png" v-else/>
                                        </div>
                                    </el-tooltip>
                                    <el-tooltip popper-class="tooltipitem" effect="light" content="置顶" placement="top" v-else>
                                        <div class="operation m-l-15 df-jc-c df-ai-c"  @mouseenter="enter(2)" @mouseleave="leave()" @click="buyAskTopping(detail.id)">
                                            <img src="@/assets/icon/topping.png" v-if="operation == 2"/>
                                            <img src="@/assets/icon/topping_not.png" v-else/>
                                        </div>
                                    </el-tooltip>
                                    <el-tooltip popper-class="tooltipitem" effect="light" content="刷新" placement="top">
                                        <div class="operation m-l-15 df-jc-c df-ai-c"  @mouseenter="enter(3)" @mouseleave="leave()" @click="refreshBuyAsk(detail.id)">
                                            <img src="@/assets/icon/refresh.png" v-if="operation == 3"/>
                                            <img src="@/assets/icon/refresh_not.png" v-else/>
                                        </div>
                                    </el-tooltip>
                                    <el-tooltip popper-class="tooltipitem" effect="light" content="删除" placement="top" v-if="0">
                                        <div class="operation m-l-15 df-jc-c df-ai-c"  @mouseenter="enter(4)" @mouseleave="leave()" @click="del(detail.id)">
                                            <img src="@/assets/icon/del.png" v-if="operation == 4"/>
                                            <img src="@/assets/icon/del_not.png" v-else/>
                                        </div>
                                    </el-tooltip>
                                </div>
                            <!--     <span class="bold m-l-10" v-for="v of detail.buyAskForms" v-if="v.buyAskCategoryForm.formType==8">{{v.value}}</span> -->
                            </div>
                            <!-- 发布者 -->
                            <div class="f14 line">
                                <!-- <div class="left"> </div> -->
                                <div class="right f12" style="margin-left:90px;color:#999"><i class="el-icon-time"></i> 发布时间：<span> {{detail.askLatestTime}}</span></div>
                            </div>
                            <div class="f14 line">
                                    <div class="left"><span>发布用户</span></div>
                                    <div class="right df-ai-c">
                                        <template v-if="detail.askAnonymityStatus">
                                            <img src="https://aliyunoss.lesoujia.com/lsj_mobile/image/niming.png" class="hiedImg"><span class="m-l-10">匿名发布</span>
                                        </template>
                                        <template v-else>
                                            <img :src="$oucy.ossUrl + detail.detailAvatar" class="hiedImg"><span class="m-l-10">{{detail.detailNickname}}</span>
                                        </template>
                                    </div>
                                </div>
                            <!-- 联系人 -->
    <!--                         <div class="f14 line" v-if="!detail.askAnonymityStatus">
                                <div class="left"><span>联系人</span></div>
                                <div class="right">{{detail.askContactName}}</div>
                            </div> -->
                           <!-- 标签 -->
                            <div class="f14 line" v-if="detail.buyAskTags">
                                <div class="left"><span>信息标签</span></div>
                                <div class="right product_tag">
                                    <div v-for="v of detail.buyAskTags" :key="v.id" v-if="detail.buyAskTags && detail.buyAskTags.length">{{v.tagName}}</div>
                                </div>
                            </div>
                            <!-- 预交金额 -->
                            <div class="f14 line">
                                <div class="left"><span>预交金额</span></div>
                                <div class="right">{{detail.askPrepayFee||0}}元</div>
                            </div>
                            <!-- 求购位置 -->
                            <div class="f14 line" v-if="detail.askContactPositionName">
                                <div class="left"><span>求购位置</span></div>
                                <div class="right">{{detail.askContactPositionName}}</div>
                            </div>
                            <div class="sxbox">
                                <div class="f14 line " v-for="v of detail.buyAskForms" :key="v.id" v-if="v.buyAskCategoryForm.formType!=8 && v.buyAskCategoryForm.formType!=7">
                                    <div class="left"><span>{{v.buyAskCategoryForm.formTitle}}</span></div>
                                    <!-- 表单类型 0文本框text 1开关 2文本域textarea 3单项选择select 4日期date 5地区 6定位位置 7上传视频 8标题（可用于搜索） 9外部链接 -->
                                    <div class="right" v-if="v.buyAskCategoryForm.formType == 1">{{v.value?'是':'否'}}</div>
                                    <div class="right" v-else-if="v.buyAskCategoryForm.formType == 6">{{v.value.addr}}</div>
                                    <div class="right" v-else-if="v.buyAskCategoryForm.formType == 7">
                                        <span v-for="vv of v.value" :key="vv.url">
                                            <el-image style="width: 200px; height: 200px;margin-left: 10px; margin-bottom:10px" :src="vv.url" :preview-src-list="srcList" v-if="vv.type=='image'"></el-image>
                                            <video style="width: 200px; height: 200px;margin-left: 10px; margin-bottom:10px" :src="vv.url" controls :preview-src-list="srcList" v-else></video>
                                        </span>
                                    </div>
                                    <div class="right" v-else-if="v.buyAskCategoryForm.formType == 9"><a :href="v.value" target="_blank"></a> {{v.value}}</div>
                                    <div class="right" v-else-if="v && v.value">{{v.value}}</div>
                                </div>
                            </div>
                            <div class="df-jc-s-b p-t-5">
                                <div class="df f14">
                                    <div class="df-ai-c btn1 m-r-10" @click="openImMessage()"><img src="@/assets/icon/grab.png" class="m-r-10" >我要抢单</div>
                                    <div class="df-ai-c btn2" @click="showPhone"><img src="@/assets/icon/phone.png" class="m-r-10"><!-- <span class="m-r-5" v-if="!detail.askAnonymityStatus">({{detail.askContactName}})</span> --> 查看电话</div>
                                </div>
                                <div class="df" >
                                    <div class="c-8 df-ai-c p-r-30 pointer" @click="sharePoster">
                                        <i class="el-icon-share m-r-10 f20"></i>
                                        <!-- <img src="@/assets/icon/collection_not.png" class="m-r-10 pointer"/> -->
                                        <!-- <img src="@/assets/icon/collection.png" class="m-r-15" v-else/> -->
                                        分享
                                    </div>
                                     <div class="c-8 df-ai-c p-r-30">
                                        <img src="@/assets/icon/collection_not.png" class="m-r-10 pointer"/>
                                        <!-- <img src="@/assets/icon/collection.png" class="m-r-15" v-else/> -->
                                        收藏({{detail.sc||0}})
                                    </div>
                                    <div class="c-8 df-ai-c"  @click="addAskSizeFavor">
                                        <img src="@/assets/icon/like_not.png" class="m-r-10 pointer"  v-if="!detail.askFavor"/>
                                        <img src="@/assets/icon/like.png" class="m-r-15" v-else/>
                                        点赞({{detail.askSizeFavors||0}})
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <div>
                    <div>留言 <span>({{ReturnsObject.totalElements}})</span></div>
                    <div class="commentBox m-t-20">
                        <div class="left">
                            <img v-if="loginUser.ltUserDetailFull" :src="$oucy.ossUrl+ loginUser.ltUserDetailFull.detailAvatar" class="pic">
                        </div>
                        <div class="right">
                            <inputBox2 ref="inputBox2" @callback="callback" placeholder="请输入留言内容" btnText="留言"></inputBox2>
                        </div>
                    </div>
                    <comment3 ref="comment3" :ReturnsObject="ReturnsObject" :parentCommentId="parentCommentId" @clickMore="clickMore" @callback="callback" @setParentCommentId="setParentCommentId"></comment3>
                </div>
                <div class="pagination" v-if="0">
                    <el-pagination v-if="ReturnsObject.content" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="ReturnsObject.size" :total="ReturnsObject.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
                    </el-pagination>
                </div>
                <div class="store" v-if="enterpriseId">
                    <div class="store_top df-ai-c">
                        <div>
                            <img :src="$oucy.ossUrl+ enterpriseinfo.enterpriseAvata" class="enterpriseAvata">
                        </div>
                        <div class="m-l-15">
                            <div class="c-1 pointer f16" @click="$oucy.go('ShopVip?enterpriseId='+enterpriseinfo.id)">{{enterpriseinfo.enterpriseName}}</div>
                            <el-rate v-model="enterpriseinfo.enterpriseScore" show-text class="m-t-10" disabled>
                            </el-rate>
                        </div>
                        <img src="@/assets/images/PublisherShop.png" class="PublisherShop">
                    </div>
                    <div class="df-jc-s-b m-t-15">
                        <div v-for="(v,i) of spuList.content" :key="i">
                            <div class="item pointer" @click="$oucy.go('/product?spuId='+v.id)">
                                <img :src="$oucy.ossUrl+v.spuImgs[0].imgUrl" v-if="v.spuImgs && v.spuImgs[0] && v.spuImgs[0].imgUrl" class="spuImgs">
                                <div class="spuImgs" v-else></div>
                                <div class="p-l-10 p-t-10 p-r-10">
                                    <div class="spuName line-2 c-3 f16">
                                        {{v.spuName}}
                                    </div>
                                    <div class="df-jc-s-b p-t-10 p-b-10 df-ai-c">
                                        <div class="c-m f16 bold ">
                                            <span class="price" v-if="v.spuMinPrise*1!=0">￥{{v.spuMinPrise}}</span>
                                            <span class="price" v-else>面议</span>
                                        </div>
                                        <div class="c-2 f12">关注({{v.quantity}})</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <refreshTopAskBuy ref="refreshTopAskBuy" @submit="setTOPRefresh"></refreshTopAskBuy>

    </div>
</template>
<script>
import oucy from "../../../util/oucyUtil";
import { localSet, localGet, localDel } from "@/store/store";

import { adHome, buyAskCategory, buyAsk, spu, userAuth ,enterpriseinfo,realnameauthentication} from "@/service"
export default {
    name: "Index",
    data() {
        return {
            value: null,
            
            detail: {},
            url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            srcList: [],
            // 返回对象
            ReturnsObject: {},
            loginUser: {},
            QueryFurnitureLibrary: {
                limit: 10,
                start: 0,
                // askAuditStatusEnum:0,
                buyAskId: null,
                askAuditStatusEnum:2,
            },
            parentCommentId: null,
            spuList: [],
            enterpriseinfo: {},
            enterpriseId: null,
            id: null,
            operation:null,
        }
    },
    watch: {
        $route: {
            handler(to, from) {
                this.getBreadcrumb()
            },
            deep: true,
            immediate: true,
        }
    },
    mounted() {
        const me = this;
/*        let enterprise = localGet('enterprise')

        // 查询客户端用户的所有企业
        userAuth.getEnterpriseByUser({}).then(res => {
            console.log(res)
            if (res && res.length) {
                this.enterpriseId = res[0].id
                localSet('enterprise', res[0])
                // this.openDialogFormVisible()
                this.findAllFurnitureSpu(this.enterpriseId)
            }
        })*/
        this.QueryFurnitureLibrary.buyAskId = this.$route.query.id
        this.id = this.$route.query.id
        this.getBuyAskById()
        this.loginUser = localGet('loginUser') || '{}'
        this.queryBuyAskComment()        
    },
    methods: {
        
        //操作鼠标移入移出
        enter(index){
            this.operation = index;
        },
        leave(){
            this.operation = null;
        },
        clickMore(v) {

            console.log(v)
            // return
            let obj = {
                limit: this.obj.size,
                start: ++this.obj.number,
            }
            buyAsk.queryBuyAskComment(obj).then(res => {
                this.ReturnsObject = res
            })
        },
        // 点击打开收起
        setParentCommentId(v) {
            if (this.parentCommentId == v.id) {
                this.parentCommentId = null
            } else {
                this.parentCommentId = v.id
            }
            // this.$emit('setParentCommentId', v)
            console.log('点了:', v.id)
            // this.parentCommentId = v.id
        },
        getBreadcrumb() {
            //$route.matched是一个数组 包含当前路由所有嵌套路径的路由记录
            let matched = this.$route.matched.filter(item => {
                if (item.path != '/') return item.name
            })
            const first = matched[0];
            if (first && first.name !== '首页') {
                matched = [{ path: '/', meta: { title: '首页' } }].concat(matched)
            }
            this.levelList = matched
            console.log(this.levelList)
        },
        getBuyAskById() {
            buyAsk.getBuyAskById({ id: this.id }).then(res => {
                if(res.enterpriseId){
                    this.enterpriseId=res.enterpriseId
                    this.findEnterpriseByLtUserAuthId()
                    this.findAllFurnitureSpu(this.enterpriseId)
                }
                for (let v of res.buyAskForms) {
                    if ((v.buyAskCategoryForm.formType == 7 || v.buyAskCategoryForm.formType == 6) && v.value && typeof v.value == 'string') {
                        v.value = JSON.parse(v.value)
                        if (v.buyAskCategoryForm.formType == 7) {
                            for (let vv of v.value) {
                                vv.url = vv.value
                                // vv.url = this.$oucy.ossUrl + vv.value
                                if (vv.type == 'image') {
                                    this.srcList.push(vv.url)
                                }
                            }
                        }
                    }
                    if (v.buyAskCategoryForm.formType == 5) {
                        v.value = v.chinaDto && v.chinaDto.namePath
                    }
                }
                this.detail = res
                this.$oucy.setTitle(this.detail.askTitle)
            })
        },

        // 评论
        callback(v) {
            console.log(v)
            if(!this.$oucy.getToken()){
                this.$confirm('此操作需要登录, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    oucy.app.eventBus.$emit(oucy.msgKeys.LOCAL_LOGIN, null);

                }).catch((err) => {
                    console.log(err)

                });
                return
            }
            let enterpriseSpus = []
            if (v.enterpriseSpus.length) {
                for (let vv of v.enterpriseSpus) {
                    enterpriseSpus.push({ type: 0, id: vv.id })
                }
            }
            if (v.enterpriseList.length) {
                for (let vv of v.enterpriseList) {
                    enterpriseSpus.push({ type: 1, id: vv.id })
                }
            }
            let AskComments = {
                parentCommentId: v.parentCommentId, //父级评论编码
                buyAskId: this.id, //
                content: v.text, //
                enterpriseSpus: enterpriseSpus.length ? JSON.stringify(enterpriseSpus) : '[]', //评论附件
            }
            buyAsk.askComments(AskComments).then(res => {
                this.$message({
                    type: 'success',
                    message: '评论成功!'
                });
                // this.QueryFurnitureLibrary.start = 0
                this.queryBuyAskComment()
                this.$refs.inputBox2.reset()
                this.$refs.comment3.reset()
            })
        },
        // 切换页数
        sizeChange: function(pageSize) {
            this.QueryFurnitureLibrary.limit = pageSize;
            this.queryBuyAskComment();
        },
        // 切换页码
        currentChange: function(current) {
            this.QueryFurnitureLibrary.start = current - 1;
            this.queryBuyAskComment();
        },

        // 查询评论列表
        queryBuyAskComment(v) {
            buyAsk.queryBuyAskComment(this.QueryFurnitureLibrary).then(res => {
                this.ReturnsObject = res
            })
        },
        lisChange(index) {
            console.log(index)
            console.log(this.$refs.remarkCarusel)
            this.$refs.remarkCarusel[0].setActiveItem(index);
        },
        findAllFurnitureSpu(enterpriseId = null) {
            spu.findAllFurnitureSpu({ start: 0, limit: 5, enterpriseId: enterpriseId ,isShelves:true,spuAuditStatus:2}).then(res => {
                this.spuList = res
            })
        },

        showPhone(){
            userAuth.queryUserBuyAskActionIsAuth({
                buyAskActionEnum: 2 // 求购操作类型 1聊天 2拨号 3查看
            }).then((result) => {
                if(result) {
                    // 是否实名
                    realnameauthentication.isRealNameAuthentication().then(res => {
                        if (res) {
                            this.$alert(this.detail.askContactPhone,'查看电话')
                            // oucy.replace('/acc/enterprise', null);
                        } else {
                            this.$confirm('你还没实名认证, 认证后才能查看?', '提示', {
                            confirmButtonText: '现在去认证',
                            cancelButtonText: '下次再认证',
                            type: 'warning'
                        }).then(() => {
                                this.$oucy.go('/acc/realname', null)
                        }).catch(() => {
                            
                        });
                        }
                    })
                } else {
                    this.$alert(result)
                }
            })
            

        },
        // 查询企业
        findEnterpriseByLtUserAuthId() {
            enterpriseinfo.findEnterpriseByLtUserAuthId({ enterpriseId: this.enterpriseId }).then(res => {
                this.enterpriseinfo = res
            })
        },
        addAskSizeFavor(){
            buyAsk.addAskSizeFavor({buyAskId:this.id}).then(res => {
                this.detail.askFavor=!this.detail.askFavor
                ++this.detail.askSizeFavors
            })
        },
        openImMessage(){
            // this.loginUser = localGet('loginUser')
            // if(this.loginUser && this.loginUser.id){
            //     this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE, {targetUserId:this.detail.askUser});
            // }else{
            //     this.$alert('请先登录','提示')
            // }
            this.loginUser = localGet('loginUser')
            if(this.loginUser && this.loginUser.id){
                userAuth.queryUserBuyAskActionIsAuth({
                    buyAskActionEnum: 1 // 求购操作类型 1聊天 2拨号 3查看
                }).then((result) => {
                    if(result) {
                        this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE, {targetUserId:this.detail.askUser});
                    } else {
                        this.$alert(result)
                    }
                }) 
            }else{
                this.$alert('请先登录','提示')
            }
            // this.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE,v);
        },

        
        del(id) {
            this.$confirm('确认删除！', '删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                console.log('点了确认')
                this.deleteBuyAsk(id)
            }).catch(() => {
                console.log('点了取消')
            });
        },
        // 删除买家发布求购信息
        deleteBuyAsk(id){
            buyAsk.deleteBuyAsk({id:id}).then(res=>{
                this.$message('删除成功')
                this.$oucy.go('askBuyList')
            })
        },
        // 刷新求购
        refreshBuyAsk(id){
            this.$refs.refreshTopAskBuy.open({v:this.detail,type:'refresh'})
            return
            buyAsk.refreshBuyAsk({id:id}).then(res=>{
                v.askUpdatetime=new Date().format('yyyy-MM-dd hh:mm:ss')
                this.$message('刷新成功')
            })
        },
       // 求购置顶 /取消置顶
        buyAskTopping(id){
            this.$refs.refreshTopAskBuy.open({v:this.detail,type:'top'})
            return
            buyAsk.buyAskTopping({id:id}).then(res=>{
                this.$message('设置成功')
                this.getBuyAskById()

            })
        },
        setTOPRefresh(){
            this.getBuyAskById()
        },
        //取消置顶
         buyAskCancel(id){
             this.$confirm('确认取消置顶！', '取消置顶', {
                 confirmButtonText: '确定',
                 cancelButtonText: '取消',
                 type: 'warning'
             }).then(() => {
                 console.log('点了确认')
                 buyAsk.buyAskCancel({id:id}).then(res=>{
                     this.$message('设置成功')
                     this.getBuyAskById()
                 })
             }).catch(() => {
                 console.log('点了取消')
             });

         },
        getFormType(v, t) {
          let has = false;
          if (v) {
            for (let vv of v) {
              if (vv.buyAskCategoryForm && vv.buyAskCategoryForm.formType == t) {
                if(vv.value && vv.value.length){
                    has = true;
                }
                break;
              }
            }
          }
          return has;
        },
        sharePoster(){
            let obj = {
                objectId: this.id,
                playbillCategory: 6,
                redirectUrlScene:{id:this.id}
            };
            this.$refs.sharePoster.show(obj)
        }
    },
}
</script>
<style scoped lang="less">
.want_main {
    margin: 0 auto;
}

.box {
    padding: 20px 0;
}

.left {

    span {
        width: 56px;
        margin-right: 10px;
        // width: 100%;
        display: inline-block;
        background: #FAFAFA;
        padding: 4px 10px;
        border-radius: 2px;
    }
}

.line {
    margin: 10px 0;
    display: flex;

    .right {
        flex: 1;
        font-size: 14px;
        font-family: SimSun;
        font-weight: 400;
        color: #666;
        line-height: 1.5;
        padding:4px 0;
    }
}

.commentBox {
    display: flex;
}

.pic {
    width: 42px;
    height: 42px;
    border-radius: 42px;
}

.right {
    flex: 1;
    padding-left: 10px
}

.name {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}

.time {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #888888;
}

.comment {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #555C68;
}

.category {
    font-weight: 400;
    color: #FFFFFF;
    font-size: 14px;
    background: #E78238;
    border-radius: 2px;
    padding: 2px 8px;
}

.sxbox {
    margin-top: -10px;
    overflow: auto;
    height: 190px;
}

.btn1 {
    background: #2090FF;
    border-radius: 2px;
    color: #fff;
    padding: 10px 48px;
    cursor: pointer;
}

.btn2 {
    background: #FF8C4E;
    border-radius: 2px;
    color: #fff;
    padding: 10px 48px;
    cursor: pointer;
}

.askBuyDetail /deep/ .el-carousel__container {
    height: 380px;
}

.store {
    background: #F7F8F9;
    padding: 20px;
    position: relative;
}

.PublisherShop {
    position: absolute;
    right: 0;
    top: 0;
}

.spuImgs {
    width: 216px;
    height: 216px;
}

.spuName {
    height: 42px;
}

.item {
    width: 216px;
    background: #fff;
}
.enterpriseAvata{
    width: 65px;
    height: 65px;
}
.product_tag {
    display: flex;

    flex-wrap: wrap;
    div {
        background: rgba(255, 255, 255, 0);
        border: 1px solid #EA8840;
        border-radius: 2px;
        color: #EA8840;
        font-size: 12px;
        padding: 0 4px;
        margin-right: 6px;
        margin-bottom: 5px;
    }
}
// 操作按钮编辑、刷新、置顶、删除
.operation{
    width: 32px;
    height: 32px;
    background: #F6F7F8;
    border-radius: 6px 6px 6px 6px;
    img{
        width: 20px;
        height: 20px;
    }
}
.hiedImg {
    width: 19px;
    height: 19px;
    border-radius: 50%;
}

div::-webkit-scrollbar{
  width:6px;
  height:6px;
  /**/
}
div::-webkit-scrollbar-track{
  background: #F6F7F8;
  border-radius:3px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
div::-webkit-scrollbar-thumb{
  background: #CECECE;
  border-radius:3px;
}
div::-webkit-scrollbar-thumb:hover{
  background: #CECECE;
}
div::-webkit-scrollbar-corner{
  background: #CECECE;
}
</style>
<style>
.el-tooltip__popper{
    border: none !important;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.10980392156862745);
    border-radius: 2px 2px 2px 2px;
    padding: 10px 20px;
    font-size: 14px;
    color: #2090FF;
}
.el-tooltip__popper[x-placement^=top] .popper__arrow{
    background:  #fff !important;
    border: none !important;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.10980392156862745);
    border-radius: 2px 2px 2px 2px;
}
</style>